import { Company, Configuration } from 'types/models/company';
import { initialSubscriptionState } from './paymentReducer';
import {
  COMPANY_CREATE_FAILURE,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_GET_FAILURE,
  COMPANY_GET_REQUEST,
  COMPANY_GET_SUCCESS,
  COMPANY_UPDATE_FAILURE,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  CompanyAction,
} from 'store/actionTypes/companyActionTypes';

type CompanyProfile = {
  data: Company;
  loading: boolean;
  error: string | null;
  creating: boolean;
  updating: boolean;
};

interface CompanyState {
  companyProfile: CompanyProfile;
}

const initialConfigurationState: Configuration = {
  id: '',
  appIdleTime: 3,
  activeSilentTracking: false,
  isScreenCapturing: false,
  screenShotsPerHour: 10,
  maximumDevicesAllowedPerUser: 1,
  isAllowReportExportForOtherUsers: false,
  allowedReportExportFormat: 'Both',
  isTrackWithinShift: false,
  allowUsersToAddBreakReasons: true,
  breakReasons: [],
  isTwoFactorAuthenticationEnabled: false,
  maxDeviceInputLable: 0,
  updateDate: 0,
  isProjectCodeGenerateAutomatically: false,
  projectCodePrefixCriteria: '',
  projectCodePrefix: '',
  isJiraIntegrationEnabled: false,
};

const initialCompanyState: Company = {
  id: '',
  name: '',
  email: '',
  hasPaid: false,
  numberOfLicenses: 0,
  expiredAt: 0,
  timezone: '',
  timeFormat: '',
  startShift: 0,
  endShift: 0,
  minWorkHours: 0,
  configurationId: '',
  stripeCustomerId: '',
  stripeSubscriptionId: '',
  monitorType: 'visible',
  subscriptionStatus: 'ACTIVE',
  profileImage: '',
  package: {
    id: '',
    name: '',
    licensePrice: 0,
    subscriptionPlan: 'monthly',
    details: '',
    pricing: {}
  },
  subscription: initialSubscriptionState,
  licenses: [],
  configurations: initialConfigurationState,
};

const initialState: CompanyState = {
  companyProfile: {
    data: initialCompanyState,
    loading: false,
    creating: false,
    updating: false,
    error: null,
  },
};

const companyReducer = (state = initialState, action: CompanyAction): CompanyState => {
  switch (action.type) {
    //payment history
    case COMPANY_GET_REQUEST:
      return {
        ...state,
        companyProfile: { ...state.companyProfile, loading: true },
      };
    case COMPANY_GET_SUCCESS:
      return {
        ...state,
        companyProfile: { ...state.companyProfile, data: action.payload, loading: false },
      };
    case COMPANY_GET_FAILURE:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          loading: false,
          error: action.payload.error,
        },
      };
    // create
    case COMPANY_CREATE_REQUEST:
      return {
        ...state,
        companyProfile: { ...state.companyProfile, creating: true, data: initialCompanyState },
      };
    case COMPANY_CREATE_SUCCESS:
      return {
        ...state,
        companyProfile: { ...state.companyProfile, data: action.payload, creating: false },
      };
    case COMPANY_CREATE_FAILURE:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          creating: false,
          error: action.payload.error,
        },
      };
    // update
    case COMPANY_UPDATE_REQUEST:
      return {
        ...state,
        companyProfile: { ...state.companyProfile, updating: true },
      };
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          data: { ...state.companyProfile.data, ...action.payload },
          updating: false,
        },
      };
    case COMPANY_UPDATE_FAILURE:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          updating: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default companyReducer;
